import { graphql } from 'gatsby'
import HTMLReactParser from 'html-react-parser'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Heading from '../../common/heading'
import Media from '../../common/media'
import StyledButton from '../../common/styledButton'

const BlockFeaturedMag = ({ content }) => {
    return (
        <section className="blkMag">
            <Container>
                <Row>
                    {content.relationships.block_featured_magazine_media &&
                        <div className="mag-image">
                            <Media media={content.relationships.block_featured_magazine_media} />
                        </div>
                    }
                    <div className="mag-info">
                        <Heading title={content.block_featured_magazine_title} caption={content.block_featured_magazine_caption} description={HTMLReactParser(content.block_featured_magazine_desc.processed)} />
                        {content.block_featured_magazine_link && <StyledButton title={content.block_featured_magazine_link.title} path={content.block_featured_magazine_link.uri} />}
                    </div>
                </Row>
            </Container>
        </section>
    )
}

export const query = graphql`
  fragment featuredMag on paragraph__block_featured_magazine {
    block_featured_magazine_title
    block_featured_magazine_link {
      title
      uri
    }
    block_featured_magazine_desc {
      processed
    }
    block_featured_magazine_caption
    relationships {
      block_featured_magazine_media {
        relationships {
          ...getImage
        }
      }
    }
    internal {
      type
    }
  }
`

export default BlockFeaturedMag