import React, { useState, useEffect } from "react"
import Blkmarquee from "../../common/marquee/marquee";
import { Col, Container, Row } from "react-bootstrap"
import { FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import { useForm } from "react-hook-form"
import BlockMediaText from "../blockMediaText/blockMediaText"
import BlockCarousel1 from "../blockCarousel1/blockCarousel1"
import BlockTwoColLayout from "../blockTwoColLayout/blockTwoColLayout"
import BlockCarousel2 from "../blockCarousel2/blockCarousel2"
import ChoixHotels from "../../common/choixHotels/choixHotels"
import BlockFeaturedMag from "../blockFeaturedMag/blockFeaturedMag"
import BlockFeeds from "../../common/blockFeeds/blockFeeds"
import BlockDeals from "../../common/blockCarousel/blockDeals"
import { useIntl } from "gatsby-plugin-react-intl"
import IllusExp from "../../common/blockIllus/blockIllus";

const DeepIntoComponents = ({ components, categories, offers, hotelId }) => {
    const intl = useIntl()
    const isSSR = typeof window === "undefined"

    let categoryId;
    //Get the selected category from url, e.g #culture
    if (typeof window !== "undefined") {
        const url = window.location.href
        const urlArray = url.split("#")

        switch (urlArray[1]) {
            case "culture":
                categoryId = "a3128826-aad4-420d-b76e-e1a21fc09613"
                break;
            case "flavours":
                categoryId = "eefefdff-4252-4e53-9120-bbd5217f8ae5"
                break;
            case "ocean":
                categoryId = "b56ad20e-622a-46da-b41e-0e2c9147dfff"
                break;
            default:
                break;
        }

    }

    const [selectedCategory, setSelectedCategory] = useState(categoryId)
    const { register } = useForm();
    // Handle category dropdown change
    const handleCategoryChange = e => {
        setSelectedCategory(e.target.value)
    }


    useEffect(() => {
        //Scrollto section filter
        const hashtag = window.location.hash
        if (hashtag) {

            setTimeout(() => {
                const section = document.querySelector(".deep-filters")
                section.scrollIntoView({ behavior: "smooth", block: "start" })
            }, 2000)

            //setSelectedCategory(categoryId)
            document.querySelector("input[value='"+categoryId+"']").click()
        }
    })

    // Filter components by selected category and return all other components without category
    const filteredComponents = components.filter((component, index) => {
        if (!selectedCategory) {
            return component
        } else {
            return (
                component.relationships?.category === undefined || component.relationships?.category?.drupal_id === selectedCategory
            )
        }
    })

    const elements = filteredComponents.map(function (component, index) {
        const type = component.internal.type

        if (type === "paragraph__marquee" && index === 0) {
            return (
                <>
                    <Blkmarquee content={component} />
                    <section className="filterForm deep-filters">
                        <Container className="filterList">
                            <form>
                                <Row>
                                    {categories.nodes && categories.nodes.length > 0 && (
                                        <Col md={12} xs={12}>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-label="category"
                                                    name="category"
                                                    value={selectedCategory}
                                                    onChange={handleCategoryChange}
                                                    defaultValue={""}
                                                >
                                                    <FormControlLabel
                                                        value=""
                                                        control={<Radio />}
                                                        label={intl.formatMessage({ id: "listing.filter-all" })}
                                                    />
                                                    {categories.nodes.map(category => (
                                                        <FormControlLabel
                                                            key={category.drupal_id}
                                                            value={category.drupal_id}
                                                            control={<Radio />}
                                                            label={category.name}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </FormControl>
                                        </Col>
                                    )}
                                </Row>
                            </form>
                        </Container>
                    </section>
                </>
            )
        }

        switch (type) {
            case "paragraph__block_media_separator_2":
                // unique id for each separator for locomotive scroll animation
                const separatorID = component.drupal_internal__id
                return (
                    <IllusExp content={component} blockID={"illusSep" + separatorID} displayTitle={1} />
                )
            case "paragraph__marquee":
                return <Blkmarquee content={component} />
            case "paragraph__block_medias_text_2":
                return <BlockMediaText content={component} />
            case "paragraph__block_carousel_1":
                return <BlockCarousel1 content={component} type={type} />
            case "paragraph__block_carousel_2":
                return <BlockCarousel1 content={component} type={type} />
            case "paragraph__block_carousel_3":
                return <BlockCarousel1 content={component} type={type} />
            case "paragraph__block_carousel_4":
                return <BlockCarousel2 content={component} type={type} />
            case "paragraph__block_featured_magazine":
                return <BlockFeaturedMag content={component} />
            case "paragraph__block_deals":
                return <BlockDeals content={component} hotelId={hotelId} offers={offers} />
            case "paragraph__block_resorts_villas":
                return <ChoixHotels content={component} />
            case "paragraph__block_two_carousels_layout":
                return <BlockTwoColLayout content={component} />
            default:
                return <></>
        }


    })

    return (
        <>
            {elements}
            <BlockFeeds />
        </>
    )
}

export default DeepIntoComponents
