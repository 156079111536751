import { graphql, Link } from 'gatsby'
import HTMLReactParser from 'html-react-parser'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Heading from '../../common/heading'
import Media from '../../common/media'

const BlockMediaText = ({ content }) => {

    return (
        <section className="blkMediaTxt">
            <Container>
                <Row>
                    <Col md={3} xs={12}>
                        {content.relationships.block_medias_text_2_medias[0] && <Media media={content.relationships.block_medias_text_2_medias[0]} />}
                    </Col>
                    <Col md={4} xs={12}>
                        <div className="meaActContent">
                            <Heading
                                heading={2}
                                caption={content.relationships.category.name}
                                title={content.block_medias_text_2_title}
                                description={HTMLReactParser(content.block_medias_text_2_description?.processed)}
                            />
                            <div className="btnList">
                                {content.block_medias_text_2_link && (
                                    <Link to={content.block_medias_text_2_link.uri} className="simpleLink">
                                        <span>{content.block_medias_text_2_link?.title}</span>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col md={5} xs={12}>
                        <div className="illusMain">
                            {content.relationships.block_medias_text_2_medias[1] && <Media media={content.relationships.block_medias_text_2_medias[1]} />}
                        </div>
                        <div className="illusSmall">
                            {content.relationships.block_medias_text_2_medias[2] && <Media media={content.relationships.block_medias_text_2_medias[2]} />}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export const query = graphql`
  fragment blockMediaText on paragraph__block_medias_text_2 {
    internal {
        type
    }
    block_medias_text_2_title
    block_medias_text_2_link {
      title
      uri
    }
    block_medias_text_2_description {
      processed
    }
    relationships {
      category: block_medias_text_2_category {
        name
        drupal_id
      }
      block_medias_text_2_medias {
        ...getImageVideo
      }
    }
}
`
export default BlockMediaText