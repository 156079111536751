import React from "react"
import { Helmet } from "react-helmet"
import { useIntl } from "gatsby-plugin-react-intl"
import "./tripAdvisor.scss"

const TripAdvisor = ({ hotelId }) => {
  const intl = useIntl()
  
  switch (hotelId) {
    case "c2d4a773-ee3f-4b1d-9a29-21eea272203e": // VERANDA PAUL ET VIRGINIE
      return intl.locale === "en" ? (
        <iframe
          src={'/tripadvisor/veranda-paulvirginie-en.html'}
          height="320px"
          frameBorder="0"
          className="iframe-widget-tripadvisor"
          title="Trip Advisor"
        />
      ) : (
        <iframe
          src={'/tripadvisor/veranda-paulvirginie-fr.html'}
          height="320px"
          frameBorder="0"
          className="iframe-widget-tripadvisor"
          title="Trip Advisor"
        />
      )
    case "0e204f9f-028c-447c-9271-f2a9f94c2d74": // VERANDA GRAND BAIE
      return intl.locale === "en" ? (
        <iframe
          src={'/tripadvisor/veranda-grandbaie-en.html'}
          height="320px"
          frameBorder="0"
          className="iframe-widget-tripadvisor"
          title="Trip Advisor"
        />
      ) : (
        <iframe
          src={'/tripadvisor/veranda-grandbaie-fr.html'}
          height="320px"
          frameBorder="0"
          className="iframe-widget-tripadvisor"
          title="Trip Advisor"
        />
      )
    case "708a5a46-6394-4f2d-a3f5-7a063a6ce201": // VERANDA POINTE AUX BICHES
      return intl.locale === "en" ? (
        <iframe
          src={'/tripadvisor/veranda-pointe-au-biches-en.html'}
          height="320px"
          frameBorder="0"
          className="iframe-widget-tripadvisor"
          title="Trip Advisor"
        />
      ) : (
        <iframe
          src={'/tripadvisor/veranda-pointe-au-biches-fr.html'}
          height="320px"
          frameBorder="0"
          className="iframe-widget-tripadvisor"
          title="Trip Advisor"
        />
      )
      case "8c705b72-3dbc-402e-89ba-3ca707a1d53f": // VERANDA TAMARIN
      return intl.locale === "en" ? (
        <iframe
          src={'/tripadvisor/veranda-tamarin-en.html'}
          height="320px"
          frameBorder="0"
          className="iframe-widget-tripadvisor"
          title="Trip Advisor"
        />
      ) : (
        <iframe
          src={'/tripadvisor/veranda-tamarin-fr.html'}
          height="320px"
          frameBorder="0"
          className="iframe-widget-tripadvisor"
          title="Trip Advisor"
        />
      )
      case "9f17836a-f3dd-42ae-a89e-e601e7ec5846": // VERANDA PALMAR
      return intl.locale === "en" ? (
        <iframe
          src={'/tripadvisor/veranda-palmar-en.html'}
          height="320px"
          frameBorder="0"
          className="iframe-widget-tripadvisor"
          title="Trip Advisor"
        />
      ) : (
        <iframe
          src={'/tripadvisor/veranda-palmar-fr.html'}
          height="320px"
          frameBorder="0"
          className="iframe-widget-tripadvisor"
          title="Trip Advisor"
        />
      )
      
    default: // VERANDA Resorts
      return intl.locale === "en" ? <Helmet></Helmet> : <Helmet></Helmet>
  }
}

export default TripAdvisor
