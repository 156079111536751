import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageIntro from "../components/common/pageIntro/pageIntro"
import MainBannerImage from "../components/common/mainBanner/mainBannerImage"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import { getCarouselImageOG } from "../components/functions"
import GenericComponents from "../components/generic/genericComponents/genericComponents"
import DeepIntoComponents from "../components/generic/genericComponents/deepIntoComponents"

export default function DeepInto({ data: { nodeDeepIntoMauritius, allTaxonomyTermDeepIntoMauritiusCategories, allNodeOffer }, pageContext }) {
  const {
    title,
    field_seo,
    relationships: {
        deep_into_mu_carousel: {
        relationships: { carousel_highlight_item: carouselMea },
      },
      deep_into_mu_introduction: intro,
      deep_into_mu_components: components,
    }
  } = nodeDeepIntoMauritius

  const intl = useIntl()

  const componentsFiltered = components.filter(
    value => Object.keys(value).length !== 0
  )

  const carouselFiltered = carouselMea.filter(
    carousel => carousel.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  return (
    <Layout pageContext={pageContext} pageTitle={title} pageClass="pageDeepInto">
       <Seo data={seoData} pageContext={pageContext} />
      {carouselFiltered.length > 0 && (
        <MainBannerImage items={carouselFiltered} />
      )}
      {intro && <PageIntro slice={intro} />}
      {/* <GenericComponents components={componentsFiltered} /> */}
      <DeepIntoComponents components={componentsFiltered} categories={allTaxonomyTermDeepIntoMauritiusCategories} offers={allNodeOffer.nodes} hotelId={pageContext.hotelId} />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!, $language: String!) {
    nodeDeepIntoMauritius(id: { eq: $id }) {
      ...deepIntoFields
    }
    allTaxonomyTermDeepIntoMauritiusCategories(
      filter: {name: {ne: "Dummy"}, langcode: {eq: $language}}
    ) {
      nodes {
        name
        drupal_id
      }
    }
    allNodeOffer(
      filter: {title: {ne: "Dummy"}, langcode: { eq: $language }}
    ) {
      nodes {
        ...deals
      }
    }
  }
`
