import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import Heading from '../../common/heading'
import "../../../assets/scss/plugins/_slick.scss"
import { graphql } from 'gatsby'
import HTMLReactParser from 'html-react-parser'
import StyledButton from '../../common/styledButton'
import Media from '../../common/media'

const BlockCarousel1 = ({ content, type }) => {

  const settings = {
    autoplay: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  }

  let backgroundImage;

  if (type === "paragraph__block_carousel_3") {
    backgroundImage = content.relationships.block_carousel_3_bg ? content.relationships.block_carousel_3_bg.relationships.field_image?.gatsbyImage : "";
  }

  return (
    <section className={type === "paragraph__block_carousel_1" ? "blkCarousel style-1" : "blkCarousel"}>
      {type === "paragraph__block_carousel_3" && content.relationships.block_carousel_3_bg &&
        <div className="bg-cover" style={{ backgroundImage: "url(" + backgroundImage + ")" }}>
        </div>
      }
      <Container>
        <Row>
          <Col md={5}>
            {
              (type === "paragraph__block_carousel_1" || type === "paragraph__block_carousel_3") &&
              <Heading caption={content.relationships.category?.name} title={content.block_carousel_title} description={HTMLReactParser(content.block_carousel_description.processed)} />
            }
            {
              type === "paragraph__block_carousel_2" &&
              <>
                <Heading caption={content.relationships.category?.name} title={content.block_carousel_title} />
                {content.relationships.block_carousel_2_featured_media &&
                  <div className="mediaCont">
                    <Media media={content.relationships.block_carousel_2_featured_media} />
                  </div>
                }
                <Heading description={HTMLReactParser(content.block_carousel_description.processed)} />
              </>
            }
            {content.block_carousel_link && <StyledButton type="link" title={content.block_carousel_link.title} path={content.block_carousel_link.uri} />}
          </Col>
          <Col md={7}>
            <div className="blkSlider">
              <Slider {...settings}>
                {type === "paragraph__block_carousel_1" && content.relationships.block_carousel_1_medias.length > 0 && (
                  content.relationships.block_carousel_1_medias.map((media, index) => (
                    <div key={index}>
                      <Media media={media} />
                    </div>
                  ))
                )}
                {type === "paragraph__block_carousel_2" && content.relationships.block_carousel_2_medias.length > 0 && (
                  content.relationships.block_carousel_2_medias.map((media, index) => (
                    <div key={index}>
                      <Media media={media} />
                    </div>
                  ))
                )}
                {type === "paragraph__block_carousel_3" && content.relationships.block_carousel_3_medias.length > 0 && (
                  content.relationships.block_carousel_3_medias.map((media, index) => (
                    <div key={index}>
                      <Media media={media} />
                    </div>
                  ))
                )}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment blockCarousel1 on paragraph__block_carousel_1 {
    internal {
        type
    }
    block_carousel_title
    block_carousel_link {
      title
      uri
    }
    block_carousel_description {
      processed
    }
    relationships {
      block_carousel_1_medias {
        ...getImageVideo
      }
      category: block_carousel_category {
        name
        drupal_id
      }
    }
}
fragment blockCarouselMediaText1 on paragraph__block_carousel_2 {
    internal {
        type
    }
    block_carousel_link {
        title
        uri
      }
      block_carousel_title
      block_carousel_description {
        processed
      }
      relationships {
        block_carousel_2_medias {
          ...getImageVideo
        }
        category: block_carousel_category {
          name
          drupal_id
        }
        block_carousel_2_featured_media {
            relationships {
              ...getImage
            }
          }
    }
}
fragment blockCarousel3 on paragraph__block_carousel_3 {
    block_carousel_title
    block_carousel_link {
      title
      uri
    }
    block_carousel_description {
      processed
    }
    internal {
      type
    }
    relationships {
      block_carousel_3_bg {
        relationships {
          ...getImage
        }
      }
      block_carousel_3_medias {
        ...getImageVideo
      }
      category: block_carousel_category {
        name
        drupal_id
      }
    }
  }
`
export default BlockCarousel1