import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import Heading from '../../common/heading'
import "../../../assets/scss/plugins/_slick.scss"
import { graphql } from 'gatsby'
import HTMLReactParser from 'html-react-parser'
import StyledButton from '../../common/styledButton'
import Media from '../../common/media'

const BlockCarousel2 = ({ content, type }) => {

  const settings = {
    autoplay: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  }

  return (
    <section className={type === "paragraph__block_carousel_4" ? "blkCarousel style-2" : "blkCarousel"}>
      <Container>
        <Row>
          <Col md={7}>
            {
              (type === "paragraph__block_carousel_4") &&
              <Heading caption={content.relationships.category?.name} title={content.block_carousel_title} />
            }
            <div className="blkSlider">
              <Slider {...settings}>
                {type === "paragraph__block_carousel_4" && content.relationships.block_carousel_4_medias.length > 0 && (
                  content.relationships.block_carousel_4_medias.map((media, index) => (
                    <div key={index}>
                      <Media media={media} />
                    </div>
                  ))
                )}
              </Slider>
            </div>
          </Col>
          <Col md={5}>
            {
              type === "paragraph__block_carousel_4" &&
              <div className="mediaCont">
                {content.relationships.block_carousel_4_featured_media && <Media media={content.relationships.block_carousel_4_featured_media} />}
              </div>
            }
            {
              (type === "paragraph__block_carousel_4") &&
              <Heading description={HTMLReactParser(content.block_carousel_description.processed)} />
            }
            {content.block_carousel_link && <StyledButton type="link" title={content.block_carousel_link.title} path={content.block_carousel_link.uri} />}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment blockCarousel4 on paragraph__block_carousel_4 {
    internal {
      type
    }
    block_carousel_title
    block_carousel_link {
      title
      uri
    }
    block_carousel_description {
      processed
    }
    relationships {
      block_carousel_4_medias {
        ...getImageVideo
      }
      block_carousel_4_featured_media {
        relationships {
          ...getImage
        }
      }
      category: block_carousel_category {
        name
        drupal_id
      }
    }
  }
`
export default BlockCarousel2