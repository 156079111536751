import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import Heading from '../../common/heading'
import "../../../assets/scss/plugins/_slick.scss"
import { graphql } from 'gatsby'
import HTMLReactParser from 'html-react-parser'
import StyledButton from '../../common/styledButton'
import Media from '../../common/media'

const BlockTwoColLayout = ({ content }) => {

    const settings = {
        autoplay: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    }

    const ColSlider = ({ items }) => {
        return (
            <div className="blkSlider">
                <Slider {...settings}>
                    {items.length > 0 && (
                        items.map((media, index) => (
                            <div key={index}>
                                <Media media={media} />
                            </div>
                        ))
                    )}
                </Slider>
            </div>
        )
    }

    const ColContent = ({ colContent }) => {
        return (
            <Col md={6}>
                {colContent.block_carousel_alignment === "top" &&  <ColSlider items={colContent.relationships.block_carousel_medias} />}
                <Heading caption={colContent.relationships.category?.name} description={HTMLReactParser(colContent.block_carousel_description.processed)} title={colContent.block_carousel_title} />
                {colContent.block_carousel_link && <StyledButton type="link" title={colContent.block_carousel_link.title} path={colContent.block_carousel_link.uri} />}
                {colContent.block_carousel_alignment === "bottom" &&  <ColSlider items={colContent.relationships.block_carousel_medias} />}
            </Col>
        )
    }

    return (
        <section className="blkCarousel style-3">
            <Container>
                <Row>
                    {content.relationships.block_carousel_1 &&
                        <ColContent colContent={content.relationships.block_carousel_1} />
                    }
                    {content.relationships.block_carousel_2 &&
                        <ColContent colContent={content.relationships.block_carousel_2} />
                    }
                </Row>
            </Container>
        </section>
    )
}

export const query = graphql`
fragment blockTwoColLayout on paragraph__block_two_carousels_layout {
    internal {
        type
    }
    relationships {
      block_carousel_1 {
        ...halfLayout
      }
      block_carousel_2 {
        ...halfLayout
      }
      category: block_carousel_category {
        drupal_id
        name
      }
    }
  }
  fragment halfLayout on paragraph__half_carousel_item {
    block_carousel_alignment
    block_carousel_description {
      processed
    }
    block_carousel_link {
      title
      uri
    }
    block_carousel_title
    relationships {
      category: block_carousel_category {
        drupal_id
        name
      }
      block_carousel_medias {
        ...getImageVideo
      }
    }
  }
`
export default BlockTwoColLayout